/**
 * Created by Chris Field on 18/04/2016
 */
define(['app'], function(app) {

  function _getAncestor(el, cls) {
    while (!app.element.hasClass(cls, el) && (el = el.parentElement));
    return el;
  }

  /**
   * Module Constructor
   * Add events to all accordion elements
   * @private
   */
  function _init() {
    var accordions = document.querySelectorAll('.accordion');
    for (var i = 0, length = accordions.length; i < length; i++) {
      _initAccordion(accordions[i]);
    }
  }

  /**
   * Bind event to toggle accordion-open/close
   * @param {object} accordionElement - Container with class accordion
   * @private
   */
  function _initAccordion(accordionElement) {
    setClasses(accordionElement, 'accordion_item', 'accordion_control', 'accordion-open', 'accordion-close');
  }


  function setClasses(rootElement, itemClassName, controlClassName, openClassName, closeClassName) {
    var toggleItem = function() {
      var toggledItem = _getAncestor(this, itemClassName);
      var accordion = rootElement;
      var isClosed = !app.element.hasClass(openClassName, toggledItem);
      var items = accordion.querySelectorAll('.' + itemClassName);
      for (var i = 0, length = items.length; i < length; i++) {
        var item = items[i];
        app.element.removeClass(openClassName, item);
        app.element.addClass(closeClassName, item);
      }
      if (isClosed) {
        app.element.removeClass(closeClassName, toggledItem);
        app.element.addClass(openClassName, toggledItem);
      }
    };

    var controls = rootElement.querySelectorAll('.' + controlClassName);
    for (var i = 0, length = controls.length; i < length; i++) {
      app.element.on('click', toggleItem, controls[i]);
    }

  }

  _init();

  return {
    setClasses: setClasses
  };

});
